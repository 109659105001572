import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  FieldCountrySelect,
  FieldCurrencySelect,
} from '../../../components';

import css from './SignupForm.module.css';

const SignupFormComponent = ({ intl, ...rest }) => (
  <FinalForm
    {...rest}
    validate={values => {
      const { password, confirmPassword } = values;

      if (password !== confirmPassword) {
        return { confirmPassword: intl.formatMessage({ id: 'SignupForm.passwordMismatch' }) };
      }
    }}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        form,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        termsAndConditions,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({ id: 'General.emailRequired' })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({ id: 'General.emailInvalid' })
      );

      // companyName

      const companyNameRequired = validators.required(
        intl.formatMessage({ id: 'SignupForm.companyNameRequired' })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({ id: 'SignupForm.businessEmailLabel' })}
              placeholder={intl.formatMessage({ id: 'SignupForm.businessEmailPlaceholder' })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.companyName` : 'companyName'}
              name="companyName"
              autoComplete="organization"
              label={intl.formatMessage({
                id: 'SignupForm.companyNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.companyNamePlaceholder',
              })}
              validate={companyNameRequired}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.customerCode` : 'customerCode'}
              name="customerCode"
              autoComplete="off"
              label={intl.formatMessage({
                id: 'SignupForm.customerCodeLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.customerCodePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({ id: 'SignupForm.customerCodeRequired' })
              )}
              maxLength={10}
              format={val => (val ? val.trim() : val)}
            />
            <FieldCountrySelect formAPI={form} />
            <FieldCurrencySelect formAPI={form} />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
              name="confirmPassword"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.confirmPasswordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            <FieldCheckbox
              name="receiveNewsletter"
              id="receiveNewsletter"
              textClassName={css.finePrint}
              label={intl.formatMessage({ id: 'SignupForm.newsletterLabel' })}
              value="true"
            />
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
